import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const getDeviceId = async () => {
  const fpPromise = FingerprintJS.load();

  try {
    const fp = await fpPromise;
    const result = await fp.get();
    if (await result.visitorId) {
      return result.visitorId;
    }
  } catch (error) {
    alert(error);
  }
  // Get the visitor identifier when you need it.
};

export const setInputFilter = (value: any) => {
  return /^\d*\.?\d*$/.test(value);
};

export const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const getCourseMode = (mode: string) => {
  switch (mode) {
    case "asdaTalk":
      return "ASDA Talk";

    case "blendedCourse":
      return "Blended Course";

    case "onlineCourse":
      return "Online Course";

    default:
      return "N/A";
  }
};

export const truncate = (input: string, length?: number) =>
  input.length > 5 ? `${input.substring(0, length || 5)}...` : input;

export const avatarCase = (str: string) => {
  var splitStr = str?.toUpperCase()?.slice(0, 2);
  return splitStr;
};

export const blockApp = (): boolean => {
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobile;
};

export const capitalize = (string: string | undefined) => {
  if (string != undefined)
    return string?.charAt(0).toUpperCase() + string?.slice(1);
};

//for form
export const generateRandomId = (length = 8) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};

export const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const generateNewItem = (fieldType: string): any => {
  const commonProperties: any = {
    id: generateRandomId(),
    label: "",
    placeholder: "",
    isRequired: true,
    order: null,
    value: "",
    isEditable: true,
  };

  switch (fieldType) {
    case "date":
      return {
        ...commonProperties,
        fieldType: "Date",
        fieldName: "Date",
        label: "Date",
      };
    case "longText":
      return {
        ...commonProperties,
        fieldType: "Long text",
        fieldName: "Long Text",
        label: "Long Text",
      };
    case "shortText":
      return {
        ...commonProperties,
        fieldType: "Short text",
        fieldName: "Short text",
        label: "Text",
      };
    case "number":
      return {
        ...commonProperties,
        fieldType: "Number",
        fieldName: "Number",
        label: "Number",
      };
    case "link":
      return {
        ...commonProperties,
        fieldType: "Link",
        fieldName: "Link",
        label: "Link",
      };
    case "toggle":
      return {
        ...commonProperties,
        fieldType: "Toggle",
        fieldName: "Toggle",
        label: "Toggle",
      };
    case "email":
      return {
        ...commonProperties,
        fieldType: "Email",
        fieldName: "Email",
        label: "Email",
      };
    case "time":
      return {
        ...commonProperties,
        fieldType: "Time",
        fieldName: "Time",
        label: "Time",
      };
    case "radio":
      return {
        ...commonProperties,
        fieldType: "Radio",
        fieldName: "Radio",
        label: "Radio",
      };
    case "checkbox":
      return {
        ...commonProperties,
        fieldType: "Checkbox",
        fieldName: "Checkbox",
        label: "Checkbox",
      };
    case "dropdown":
      return {
        ...commonProperties,
        fieldType: "Dropdown",
        fieldName: "Dropdown",
        label: "Dropdown",
      };
    default:
      return {};
  }
};

export const getContrastingColor = (color: any) => {
  // Remove the '#' character if it exists
  const hex = color.replace("#", "");

  // Convert the hex string to RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the brightness of the color
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Return black or white depending on the brightness
  return brightness > 128 ? "black" : "white";
};
