import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  colours: {
    PRIMARY: {
      PRIMARY_5: "#E9EDEB",
      PRIMARY_10: "#ccddd6",
      PRIMARY_20: "#ABC6BA",
      PRIMARY_30: "#81a998",
      PRIMARY_40: "#568c76",
      PRIMARY_50: "#2c7053",
      BASE: " #025331",
      PRIMARY_60: "#024529",
      PRIMARY_70: "#013721",
      PRIMARY_80: "#012a19",
      PRIMARY_90: "#011c10",
      PRIMARY_100: "#00110a",
    },

    SECONDARY: {
      BASE: "#43B177",
    },

    SUPPORTING: {
      BASE: "#f67d6d",
    },

    SUPPORTING_2: {
      SUPPORTING_2_10: "#f2f4e9",
      SUPPORTING_2_20: "#eaecda",
      SUPPORTING_2_30: "#dfe3c8",
      SUPPORTING_2_40: "#d4dab6",
      SUPPORTING_2_50: "#cad0a3",
      BASE: "#bfc791",
      SUPPORTING_2_60: "#9fa679",
      SUPPORTING_2_70: "#7f8561",
      SUPPORTING_2_80: "#606449",
      SUPPORTING_2_90: "#404230",
      SUPPORTING_2_100: "#26281d",
    },

    TEXT: {
      PRIMARY: "#202020",
      SECONDARY: "#6c6c6c",
      WHITE: "#fff",
      BLACK: "#000",
      ICON_COLOR: "#292D32",
      RED: "#FF5656",
    },

    BG: {
      PRIMARY: "#025331",
      PRIMARY_90: "#667085",
      SECONDARY: "#43b177",
      LIGHT_GRAY: "#ECECEC",
      WHITE: "#FFF",
      GRAY_50: "#F9FAFB",
      BLACK: "#000",
    },

    NEUTRAL: {
      NEUTRAL_5: "#F5F5F5",
      NEUTRAL_10: "#ececec",
      NEUTRAL_20: "#e0e0e0",
      NEUTRAL_30: "#d1d1d1",
      NEUTRAL_40: "#c1c1c1",
      NEUTRAL_50: "#b2b2b2",
      BASE: "#a2a2a2",
      NEUTRAL_60: "#878787",
      NEUTRAL_70: "#6c6c6c",
      NEUTRAL_80: "#515151",
      NEUTRAL_90: "#363636",
      NEUTRAL_100: "#202020",
    },

    BORDER: {
      LIGHT: "#ececec",
      BASE: "#e0e0e0",
    },

    STATUS: {
      BLUE: "#2C6EDC",
      GREEN: "#1DAA5E",
      SECONDARY: "#1DAA5E",
      RED: "#FF5656",
    },

    ALERT: {
      ERROR_5: "#FEF3F2",
      ERROR_BASE: "#FF5656",
      ERROR_60: "#D92D20",
      ERROR_70: "#B42318",
      SUCCESS_5: "#ECFDF3",
      SUCCESS_60: "#039855",
      SUCCESS_70: "#027A48",
      WARNING_BASE: "#FF8C27",
    },
  },
};

const colors = createSlice({
  name: "colors",
  initialState,
  reducers: {
    setColors: (state: any, action: any) => {
      // console.log(action.payload)
      state.colours.PRIMARY.BASE = action.payload[0].hex;
      // console.log(state.PRIMARY.BASE)
    },
  },
});

export const { setColors } = colors.actions;

export default colors.reducer;
