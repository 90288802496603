enum API {
  LOGIN = "/auth/login",
  SIGNUP = "/auth/signup",
  LOGOUT = "/auth/logout",
  CREATE_PASSWORD = "/pm-hermes/auth/set-password",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/reset-password",
  VALIDATE_RESET_PASSWORD = "/auth/reset-password/validate",
  REFRESH_TOKEN = "auth/refresh",
  PROFILE = "/user/profile",
  GET_ROLES = "process-pilot-provisioning-service/api/roles",
  GET_PERMISSION_DETAILS = "process-pilot-provisioning-service/api/roles/details",
  GET_USER = "process-pilot-provisioning-service/api/users",
  // ROLES = "/pm-hermes/health",
}

const publicEndpoints = [
  API.FORGOT_PASSWORD,
  API.LOGIN,
  API.RESET_PASSWORD,
  API.LOGOUT,
  // API.REFRESH_TOKEN,
  API.RESET_PASSWORD,
  API.SIGNUP,
  API.VALIDATE_RESET_PASSWORD,
];

export { publicEndpoints };

export default API;
