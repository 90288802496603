import { APP_NAME } from "@/constants/constants";
import LogoImage from "@assets/svg/logo.svg";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

type LogoProps = {
  width?: string;
  fontSize?: string;
};

export const Logo: React.FC<LogoProps> = ({ width, fontSize }) => {
  const navigate = useNavigate();
  return (
    <Link
      className="flex items-center gap-2  object-cover cursor-pointer"
      style={{ width }}
      to={"/admin/dashboard/overview"}
    >
      <img
        src={LogoImage}
        alt={`${APP_NAME} logo`}
        className="w-[180px] h-[39px] object-cover"
      />
      {/* <p className="text-[22px] font-[poppins]" style={{ fontSize }}>
        <span className="text-PRIMARY-PRIMARY_50 font-semibold">
          {APP_NAME?.substring(0, 7)}
        </span>
        <span className="text-PRIMARY-PRIMARY_20 font-semibold">
          {APP_NAME?.substring(7, 13)}
        </span>
        <span className="text-PRIMARY-PRIMARY_20 font-light">
          {APP_NAME?.substring(13)}
        </span>
      </p> */}
    </Link>
  );
};
