export const APP_NAME = "ProcessPilot.ai";

export const dummyFormTemplates = [
  {
    taskName: "Dummy Templates",
    form: {
      fields: [
        {
          fieldType: "longText",
        },
        {
          fieldType: "shortText",
        },
        {
          fieldType: "link",
        },
        {
          fieldType: "email",
        },
      ],
    },
  },
  {
    taskName: "Dummy Templates",
    form: {
      fields: [
        {
          fieldType: "dropdown",
        },
        {
          fieldType: "date",
        },
        {
          fieldType: "time",
        },
        {
          fieldType: "checkbox",
        },
      ],
    },
  },
  {
    taskName: "Dummy Templates",
    form: {
      fields: [
        {
          fieldType: "number",
          label: "Number",
        },
        {
          fieldType: "toggle",
        },
        {
          fieldType: "radio",
        },
        {
          fieldType: "multiselect",
        },
      ],
    },
  },
  {
    taskName: "Dummy Templates",
    form: {
      fields: [
        {
          fieldType: "date",
        },
        {
          fieldType: "shortText",
        },
        {
          fieldType: "dropdown",
        },
        {
          fieldType: "checkbox",
        },
      ],
    },
  },
];
