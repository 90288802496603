import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { reOrder, setItem } from "@/store/slices/dragSlice";
import { generateNewItem, reorder } from "@/utils/functions";

export const useOnDragEnd = () => {
  const dispatch = useAppDispatch();
  const drag = useAppSelector((state) => state.drag);
  return (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === "ITEMS" && destination.droppableId === "ITEMS") {
      const reorderedItems = reorder(
        drag.item,
        source.index,
        destination.index
      );
      dispatch(reOrder(reorderedItems));
    }

    if (source.droppableId === "ITEMS_SIDE") {
      const draggedItem = drag?.fields[source.index];
      const newItem = generateNewItem(draggedItem.fieldType);
      dispatch(setItem(newItem));
    }

    if (source.droppableId === "TEMPLATE_SIDE") {
      const draggedItem = drag?.templates[source.index];

      const newItem = draggedItem.form.fields.map((item: any) => {
        return generateNewItem(item.fieldType);
      });

      newItem.forEach((item: any) => {
        dispatch(setItem(item));
      });

      dispatch(setItem(newItem));
    }
  };
};
