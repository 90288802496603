import ROLES from "../config/roles";

export const PUBLIC = {
  BASE_PATH: "/",
  PAGES: {
    LANDING: "home",
  },
};

// AUTH
export const AUTH = {
  BASE_PATH: "/auth",
  PAGES: {
    LOGIN: "login",
    FORGOT_PASSWORD: "forgot-password",
    CONFIRM_FORGOT_PASSWORD: "forgot-password/confirm",
    RESET_PASSWORD: "reset-password",
    SUCCESS_RESET_PASSWORD: "reset-password/success",
    STUDENT_REGISTRATION: "student-registration",
    SELECT_OWNER:"select-owner",
    WELCOME: {
      ACCEPT: "accept-invite",
      PERSONAL_INFORMATION: "personal-information",
      CREATE_PASSWORD: "create-password",
      INDEX: "welcome",
    },
  },
};

//PRIVATE
export const PRIVATE = {
  BASE_PATH: "/:userType",
  ADMIN: {
    ADMIN_BASE_PATH: ROLES.ADMIN,
    SUPER_ADMIN_BASE_PATH: ROLES.SUPER_ADMIN,
    PAGES: {
      INDEX: "dashboard",
      DASHBOARD: {
        INDEX: "overview",
        WORKSPACE: "workspace",
        DRIVE: "drive",
        LIBRARY: "library",
        COMPANY:"company",
        PROCESS_CREATION: "process-creation",
      },    
      PROCESS_CREATION: {
        INDEX: "process",
      },
      USERS: {
        INDEX: "manage-users",
        INDIVIDUAL_USERS: "individual-users",
        SINGLE_USERS: {
          INDEX: "individual-users/:id",
          WORKSPACE: "workspace",
          DRIVE: "drive",
          ACTIVITY: "activity",
        },
        USER_GROUPS: "user-groups",
        ROLES: "roles",
      },
      TASK: "task",
      CHANGE_PASSWORD: "settings/change-password",
      CHAT: "chat",
      SETTINGS:{
        INDEX:"settings",
        MY_PROFILE:'my-profile',
        GENERAL_SETTINGS: "general-settings",
        FEATURES: "features",
        COMPANY_SETTINGS: {
          INDEX: "company-settings",
          BUSINESS_DETAILS: "business-details",
          OWNER_DETAILS: "owner-details",
          MOBILE_APP: "mobile-app",
          SUPPORT_DETAILS: "support-details",
          CUSTOMIZATION: "customization",
        },
      },
    },
   

      COMPANY_DETAILS: {
        INDEX: 'company-details/:id',
        OVERVIEW: "overview",
        EMPLOYEES:"employees",
        DRIVE: "drive",
        ACTIVITY: "activity",
      },


  },
};

export const ERROR = {
  ERROR_403: "/403",
  CATCH_ALL: "*",
};
