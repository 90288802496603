import React, { lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  Navigator,
  useLocation,
} from "react-router-dom";

import { PUBLIC, PRIVATE, ERROR, AUTH } from "./routes";
import { useAppSelector } from "../store/hooks";

import Loader from "../components/common/loader";

const PublicLayout = lazy(() => import("../layouts/publicLayout"));
const AuthLayout = lazy(() => import("../layouts/authLayout"));
const AdminLayout = lazy(() => import("../layouts/adminLayout"));

const Landing = lazy(() => import("../pages/public/landing"));

//auth
const Login = lazy(() => import("../pages/auth/login"));
const OwnerSelect = lazy(() => import("../pages/auth/login/OwnerSelect"));
const ForgotPassword = lazy(() => import("../pages/auth/forgotPassword"));
const ResetPassword = lazy(() => import("@pages/auth/resetPassword"));

//auth modal
const ConfirmForgotPassword = lazy(
  () => import("../pages/auth/forgotPassword/confirm")
);
const SuccessResetPassword = lazy(
  () => import("@pages/auth/resetPassword/success")
);

//welcome
const AcceptInvite = lazy(() => import("@/pages/auth/welcome/acceptInvite"));
const PersonalInformation = lazy(
  () => import("@/pages/auth/welcome/personalInformation")
);
const CreatePassword = lazy(
  () => import("@/pages/auth/welcome/createPassword")
);

//dashboard
const Overview = lazy(() => import("../pages/admin/dashboard/overview"));
const Dashboard = lazy(() => import("../pages/admin/dashboard"));
const Workspace = lazy(() => import("../pages/admin/dashboard/workspace"));
const Library = lazy(() => import("../pages/admin/dashboard/library"));
const Company = lazy(() => import("../pages/admin/dashboard/Company"));

// //Company Details
const CompanyDetails = lazy(() => import("@pages/admin/companyDetails"));

const CompanyOverview = lazy(
  () => import("@pages/admin/companyDetails/overview")
);
const CompanyEmployees = lazy(
  () => import("@pages/admin/companyDetails/employees")
);
const CompanyDrive = lazy(() => import("@pages/admin/companyDetails/drive"));
const CompanyActivity = lazy(
  () => import("@pages/admin/companyDetails/activity")
);

//process creation
const ProcessCreation = lazy(() => import("@/pages/admin/processCreation"));

//manage users
const ManageUsers = lazy(() => import("../pages/admin/manageUsers"));
const IndividualUsers = lazy(
  () => import("../pages/admin/manageUsers/individualUsers")
);
const UserGroups = lazy(() => import("../pages/admin/manageUsers/userGroups"));

const RolesManagement = lazy(() => import("@pages/admin/manageUsers/roles"));

//Single User
const SingleUser = lazy(
  () => import("../pages/admin/manageUsers/individualUsers/singleUser")
);
const UserWorkspace = lazy(
  () =>
    import("../pages/admin/manageUsers/individualUsers/singleUser/workspace")
);
const UserActivity = lazy(
  () => import("../pages/admin/manageUsers/individualUsers/singleUser/activity")
);
const UserDrive = lazy(
  () => import("../pages/admin/manageUsers/individualUsers/singleUser/drive")
);

//chat
const Chat = lazy(() => import("../pages/admin/chat"));

//Settings
const Settings = lazy(() => import("../pages/admin/settings"));
const MyProfile = lazy(() => import("../pages/admin/settings/myProfile"));
const GeneralSettings = lazy(
  () => import("../pages/admin/settings/generalSettings")
);
const CompanySettings = lazy(
  () => import("../pages/admin/settings/companySettings")
);
const BusinessDetails = lazy(
  () => import("../pages/admin/settings/companySettings/businessDetails")
);
const OwnerDetails = lazy(
  () => import("../pages/admin/settings/companySettings/ownerDetails")
);
const MobileApp = lazy(
  () => import("../pages/admin/settings/companySettings/mobileApp")
);
const SupportDetails = lazy(
  () => import("../pages/admin/settings/companySettings/supportDetails")
);
const CustomizationSettings = lazy(
  () => import("../pages/admin/settings/companySettings/customization")
);

const Error = lazy(() => import("../pages/common/Error"));

const MainRoute = () => {
  const location = useLocation();
  const { isAppInitialized } = useAppSelector((state: any) => state.user);

  if (!isAppInitialized) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Public Routes */}
        <Route path={PUBLIC.BASE_PATH} element={<PublicLayout />}>
          <Route
            path={PUBLIC.PAGES.LANDING}
            element={
              <Suspense fallback={<Loader />}>
                <Landing />
              </Suspense>
            }
          />

          <Route index element={<Navigate to={AUTH.BASE_PATH} />} />
        </Route>

        {/* Auth Routes */}
        <Route path={AUTH.BASE_PATH} element={<AuthLayout />}>
          <Route
            path={AUTH.PAGES.LOGIN}
            element={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />

          <Route
            path={AUTH.PAGES.SELECT_OWNER}
            element={
              <Suspense fallback={<Loader />}>
                <OwnerSelect />
              </Suspense>
            }
          />

          <Route
            path={AUTH.PAGES.CONFIRM_FORGOT_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <ConfirmForgotPassword />
              </Suspense>
            }
          />

          <Route
            path={AUTH.PAGES.FORGOT_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path={AUTH.PAGES.SUCCESS_RESET_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <SuccessResetPassword />
              </Suspense>
            }
          />
          <Route
            path={AUTH.PAGES.RESET_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <ResetPassword />
              </Suspense>
            }
          />
          {/* welcome */}
          <Route path={AUTH.PAGES.WELCOME.INDEX}>
            <Route
              path={AUTH.PAGES.WELCOME.ACCEPT}
              element={
                <Suspense fallback={<Loader />}>
                  <AcceptInvite />
                </Suspense>
              }
            />

            <Route
              path={AUTH.PAGES.WELCOME.PERSONAL_INFORMATION}
              element={
                <Suspense fallback={<Loader />}>
                  <PersonalInformation />
                </Suspense>
              }
            />
            <Route
              path={AUTH.PAGES.WELCOME.CREATE_PASSWORD}
              element={
                <Suspense fallback={<Loader />}>
                  <CreatePassword />
                </Suspense>
              }
            />
          </Route>
          <Route index element={<Navigate to={AUTH.PAGES.LOGIN} />} />
        </Route>

        {/* Admin Routes */}
        <Route
          path={PRIVATE.ADMIN.ADMIN_BASE_PATH}
          element={<AdminLayout isSuperAdmin />}
        >
          {/* company profile */}
          <Route
            path={PRIVATE.ADMIN.COMPANY_DETAILS.INDEX}
            element={
              <Suspense fallback={<Loader />}>
                <CompanyDetails />
              </Suspense>
            }
          >
            <Route
              path={PRIVATE.ADMIN.COMPANY_DETAILS.OVERVIEW}
              element={
                <Suspense fallback={<Loader />}>
                  <CompanyOverview />
                </Suspense>
              }
            />

            <Route
              path={PRIVATE.ADMIN.COMPANY_DETAILS.EMPLOYEES}
              element={
                <Suspense fallback={<Loader />}>
                  <CompanyEmployees />
                </Suspense>
              }
            />

            <Route
              path={PRIVATE.ADMIN.COMPANY_DETAILS.DRIVE}
              element={
                <Suspense fallback={<Loader />}>
                  <CompanyDrive />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.COMPANY_DETAILS.ACTIVITY}
              element={
                <Suspense fallback={<Loader />}>
                  <CompanyActivity />
                </Suspense>
              }
            />
            <Route
              index
              element={<Navigate to={PRIVATE.ADMIN.COMPANY_DETAILS.OVERVIEW} />}
            />
          </Route>

          {/* Workspace routes */}
          <Route
            path={PRIVATE.ADMIN.PAGES.INDEX}
            element={
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            }
          >
            <Route
              path={PRIVATE.ADMIN.PAGES.DASHBOARD.INDEX}
              element={
                <Suspense fallback={<Loader />}>
                  <Overview />
                </Suspense>
              }
            />

            <Route
              path={PRIVATE.ADMIN.PAGES.DASHBOARD.WORKSPACE}
              element={
                <Suspense fallback={<Loader />}>
                  <Workspace />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.DASHBOARD.LIBRARY}
              element={
                <Suspense fallback={<Loader />}>
                  <Library />
                </Suspense>
              }
            />

            {/*  company routses */}
            <Route
              path={PRIVATE.ADMIN.PAGES.DASHBOARD.COMPANY}
              element={
                <Suspense fallback={<Loader />}>
                  <Company />
                </Suspense>
              }
            ></Route>

            <Route
              path={PRIVATE.ADMIN.PAGES.DASHBOARD.DRIVE}
              element={
                <Suspense fallback={<Loader />}>
                  <Workspace />
                </Suspense>
              }
            />
            {/* { Process creation routes} */}
            <Route
              path={PRIVATE.ADMIN.PAGES.DASHBOARD.PROCESS_CREATION}
              element={
                <Suspense fallback={<Loader />}>
                  <ProcessCreation />
                </Suspense>
              }
            />
          </Route>

          {/* Manage users */}
          <Route path={PRIVATE.ADMIN.PAGES.USERS.INDEX}>
            <Route
              element={
                <Suspense>
                  <ManageUsers />
                </Suspense>
              }
            >
              <Route
                path={PRIVATE.ADMIN.PAGES.USERS.INDIVIDUAL_USERS}
                element={
                  <Suspense>
                    <IndividualUsers />
                  </Suspense>
                }
              />
              <Route
                path={PRIVATE.ADMIN.PAGES.USERS.USER_GROUPS}
                element={
                  <Suspense>
                    <UserGroups />
                  </Suspense>
                }
              />
              <Route
                path={PRIVATE.ADMIN.PAGES.USERS.ROLES}
                element={
                  <Suspense>
                    <RolesManagement />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path={PRIVATE.ADMIN.PAGES.USERS.SINGLE_USERS.INDEX}
              element={
                <Suspense>
                  <SingleUser />
                </Suspense>
              }
            >
              <Route
                path={PRIVATE.ADMIN.PAGES.USERS.SINGLE_USERS.WORKSPACE}
                element={
                  <Suspense>
                    <UserWorkspace />
                  </Suspense>
                }
              />
              <Route
                path={PRIVATE.ADMIN.PAGES.USERS.SINGLE_USERS.ACTIVITY}
                element={
                  <Suspense>
                    <UserActivity />
                  </Suspense>
                }
              />
              <Route
                path={PRIVATE.ADMIN.PAGES.USERS.SINGLE_USERS.DRIVE}
                element={
                  <Suspense>
                    <UserDrive />
                  </Suspense>
                }
              />
              <Route
                index
                element={
                  <Navigate
                    to={PRIVATE.ADMIN.PAGES.USERS.SINGLE_USERS.WORKSPACE}
                  />
                }
              />
            </Route>
            <Route
              index
              element={
                <Navigate to={PRIVATE.ADMIN.PAGES.USERS.INDIVIDUAL_USERS} />
              }
            />
          </Route>
          <Route
            path={PRIVATE.ADMIN.PAGES.CHAT}
            element={
              <Suspense>
                <Chat />
              </Suspense>
            }
          />

          {/* Settings routes */}
          <Route
            path={PRIVATE.ADMIN.PAGES.SETTINGS.INDEX}
            element={<Settings />}
          >
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS.MY_PROFILE}
              element={<MyProfile />}
            />
            <Route path={PRIVATE.ADMIN.PAGES.SETTINGS.FEATURES} />
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS.GENERAL_SETTINGS}
              element={<GeneralSettings />}
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS.COMPANY_SETTINGS.INDEX}
              element={<CompanySettings />}
            >
              <Route
                path={
                  PRIVATE.ADMIN.PAGES.SETTINGS.COMPANY_SETTINGS.BUSINESS_DETAILS
                }
                element={<BusinessDetails />}
              />
              <Route
                path={
                  PRIVATE.ADMIN.PAGES.SETTINGS.COMPANY_SETTINGS.OWNER_DETAILS
                }
                element={<OwnerDetails />}
              />
              <Route
                path={PRIVATE.ADMIN.PAGES.SETTINGS.COMPANY_SETTINGS.MOBILE_APP}
                element={<MobileApp />}
              />
              <Route
                path={
                  PRIVATE.ADMIN.PAGES.SETTINGS.COMPANY_SETTINGS.SUPPORT_DETAILS
                }
                element={<SupportDetails />}
              />
              <Route
                path={
                  PRIVATE.ADMIN.PAGES.SETTINGS.COMPANY_SETTINGS.CUSTOMIZATION
                }
                element={<CustomizationSettings />}
              />
              <Route
                index
                element={
                  <Navigate
                    to={
                      PRIVATE.ADMIN.PAGES.SETTINGS.COMPANY_SETTINGS
                        .BUSINESS_DETAILS
                    }
                  />
                }
              />
            </Route>
            <Route
              index
              element={
                <Navigate to={PRIVATE.ADMIN.PAGES.SETTINGS.MY_PROFILE} />
              }
            />
          </Route>

          <Route index element={<Navigate to={PRIVATE.ADMIN.PAGES.INDEX} />} />

          <Route
            path={ERROR.CATCH_ALL}
            element={
              <Suspense fallback={<Loader />}>
                <Error type={404} />
              </Suspense>
            }
          />
        </Route>

        {/* tenent routes */}
        <Route path={PRIVATE.BASE_PATH} element={<AuthLayout />}>
          <Route
            path={`/:userType/auth/welcome/accept-invite`}
            element={
              <AcceptInvite />
              // <Navigate
              //   to={{
              //     pathname: "*/auth/welcome/accept-invite",
              //     search: location.search,
              //   }}
              // />
            }
          />
          <Route
            path={`/:userType/auth/welcome/create-password`}
            element={
              <CreatePassword />
              // <Navigate
              //   to={{
              //     pathname: "*/auth/welcome/accept-invite",
              //     search: location.search,
              //   }}
              // />
            }
          />
        </Route>
        {/* Other Routes */}
        <Route
          path={ERROR.CATCH_ALL}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={404} />
            </Suspense>
          }
        />
        <Route
          path={ERROR.ERROR_403}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={403} />
            </Suspense>
          }
        />
        <Route index element={<Navigate to={PUBLIC.PAGES.LANDING} />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
